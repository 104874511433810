import { yupResolver } from "@hookform/resolvers/yup";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setFiltroEmpresa, setFiltroGrupoEconomicoDrop } from "../../actions";
import {
  getApiViaCep,
  getGrupoEconomicoByNomeFantasiaEAtivo,
  saveEmpresa,
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired, messageRequiredMinimumCharacters, messageValidEmail } from "../../default/messages";
import { AutoCompletarComponent } from "../AutoCompletar";
import { ButtonComponent } from "../ButtonComponent";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import { EMAIL_REGEX } from "../../utils/regex";
import "./styles.css";

export const EditarEmpresa = ({ editEmpresa, page }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const toast = useRef(null);
  const [dialogCadastroEmpresa, setDialogCadastroEmpresa] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState(null);
  const [nomeFantasia, setNomeFantasia] = useState(null);
  const [id, setId] = useState(null);
  const [inscEstadual, setInscEstadual] = useState(null);
  const [inscMunicipal, setInscMunicipal] = useState(null);
  const [ufInscricao, setUfInscricao] = useState(null);
  const [logradouro, setLogradouro] = useState(null);
  const [numLogradouro, setNumLogradouro] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [estado, setEstado] = useState(null);
  const [pais, setPais] = useState(null);
  const [emailNotaFiscal, setEmailNotaFiscal] = useState(null);
  const [status, setStatus] = useState(null);
  const [ddd, setDdd] = useState(null);
  const [telefone, setTelefone] = useState(null);
  const [cnpj, setCnpj] = useState(null);
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState(null);
  const [idGrupoEconomico, setIdGrupoEconomico] = useState(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [nomeFantasiaGrupoEconomico, setNomeFantasiaGrupoEconomico] =
    useState(null);

  const [allGrupoEconomico, setAllGrupoEconomico] = useState([]);

  const validationPost = yup.object({
    nomeFantasiaGrupoEconomico: yup.string().required(messageRequired),
    status: yup.string().required(messageRequired),
    nomeFantasia: yup.string().trim()
      .min(3, messageRequiredMinimumCharacters("O Nome Fantasia", 2)),
    razaoSocial: yup.string().required(messageRequired).trim(),
    cnpj: yup.string().required(messageRequired),
    emailNotaFiscal: yup.string().email(messageValidEmail).required(messageRequired).matches(EMAIL_REGEX, messageValidEmail),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const optionsStatus = [
    { name: "Ativa", value: "Ativo" },
    { name: "Inativa", value: "Inativo" },
  ];

  function settingCepDataWithApiReponse(data) {
    setCidade(data?.localidade);
    setLogradouro(data?.logradouro);
    setEstado(data?.uf);
    setComplemento(data?.complemento);
    setBairro(data?.bairro);
  }

  function viaCep() {
    if (cep?.length > 7 && cep?.length < 10) {
      getApiViaCep(cep).then((data) => {
        settingCepDataWithApiReponse(data);
      });
    }
  }

  useEffect(() => {
    if (editEmpresa) {
      setNomeFantasiaGrupoEconomico(editEmpresa.grupoEconomico?.nomeFantasia);
      setIdGrupoEconomico(editEmpresa.grupoEconomico?.id);
      setId(editEmpresa.id);
      setRazaoSocial(editEmpresa.razaoSocial);
      setNomeFantasia(editEmpresa.nomeFantasia);
      setInscEstadual(editEmpresa.inscEstadual);
      setInscMunicipal(editEmpresa.inscMunicipal);
      setUfInscricao(editEmpresa.ufInscricao);
      setLogradouro(editEmpresa.logradouro);
      setNumLogradouro(editEmpresa.numLogradouro);
      setComplemento(editEmpresa.complemento);
      setCidade(editEmpresa.cidade);
      setEstado(editEmpresa.estado);
      setPais(editEmpresa.pais);
      setEmailNotaFiscal(editEmpresa.emailNotaFiscal);
      setStatus(editEmpresa.status);
      setDdd(editEmpresa.ddd);
      setTelefone(editEmpresa.telefone);
      setCnpj(editEmpresa.cnpj);
      setCep(editEmpresa.cep);
      setBairro(editEmpresa.bairro);
      setValue("status", editEmpresa.status);
      setValue("razaoSocial", editEmpresa.razaoSocial);
      setValue("cnpj", editEmpresa.cnpj);
      setValue("emailNotaFiscal", editEmpresa.emailNotaFiscal);
      setValue("nomeFantasia", editEmpresa.nomeFantasia);
    }
  }, [editEmpresa, setValue]);

  function validandandoCpfCpnj() {
    if (cnpj) {
      return cnpj
        ?.replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll("_", "");
    }
  }

  const empresa = {
    id: id,
    nomeFantasia: nomeFantasia,
    razaoSocial: razaoSocial?.trim(),
    grupoEconomico: {
      id: nomeFantasiaGrupoEconomico?.id
        ? nomeFantasiaGrupoEconomico?.id
        : idGrupoEconomico,
    },
    cnpj: validandandoCpfCpnj(),
    inscEstadual: inscEstadual,
    inscMunicipal: inscMunicipal,
    ufInscricao: ufInscricao,
    logradouro: logradouro,
    numLogradouro: numLogradouro,
    complemento: complemento,
    cidade: cidade,
    estado: estado,
    pais: pais,
    emailNotaFiscal: emailNotaFiscal,
    status: status,
    ddd: ddd,
    telefone: telefone,
    cep: cep,
    bairro: bairro,
  };

  async function salvarEditarEmpresa() {
    setCarregandoRequisicao(true);
    try {
      await saveEmpresa(empresa).then((resultado) => {
        if (editEmpresa) {
          toast.current.show({
            severity: "success",
            summary: "Sucesso!",
            detail: "Empresa alterada com sucesso!",
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Sucesso!",
            detail: "Empresa salva com sucesso!",
          });
        }
        setDialogCadastroEmpresa(false);
        dispatch(setFiltroEmpresa(reduxStateInicial.filtroEmpresa));
        setTimeout(() => {
          history.push("/empresas");
        }, 1500);
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const onlyNumbersRegex = /^[0-9\b]+$/;

  const closeModal = () => {
    validationPost.isValid(empresa).then((response) => {
      setDialogCadastroEmpresa(false);
    });
  };

  useEffect(() => {
    if (nomeFantasiaGrupoEconomico?.nomeFantasia !== undefined) {
      setValue(
        "nomeFantasiaGrupoEconomico",
        nomeFantasiaGrupoEconomico.nomeFantasia
      );
    } else if (
      nomeFantasiaGrupoEconomico === editEmpresa?.grupoEconomico?.nomeFantasia
    ) {
      setValue("nomeFantasiaGrupoEconomico", nomeFantasiaGrupoEconomico);
    } else if (
      nomeFantasiaGrupoEconomico !== editEmpresa?.grupoEconomico?.nomeFantasia
    ) {
      setValue("nomeFantasiaGrupoEconomico", undefined);
    }
  }, [nomeFantasiaGrupoEconomico, setValue, editEmpresa]);

  function getGrupoEconomicoList(buscarPor) {
    callFilterEndpoints(
      setAllGrupoEconomico,
      getGrupoEconomicoByNomeFantasiaEAtivo,
      buscarPor,
      "Grupo Econômico"
    );
  }
  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param && endpoint !== undefined) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não encontrado. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true)
    } else {
      history.push("/empresas", { page: page })
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <div className="display-root">
          <Toast ref={toast} />
          <ModalComponents
            visible={alteracoesFeitas}
            onHide={() => setAlteracoesFeitas(false)}
            onClickCancelar={() => setAlteracoesFeitas(false)}
            onClickConfirmar={() => {
              history.push("/empresas", { page: page })
            }}
            onClick="warning"
            descricao={modalWarning}
          />
          <ModalComponents
            editOrRegister={editEmpresa}
            visible={dialogCadastroEmpresa}
            onHide={() => setDialogCadastroEmpresa(false)}
            title="Empresa"
            onClick="confirm"
            onClickCancelar={() => setDialogCadastroEmpresa(false)}
            handleSubmit={handleSubmit}
            onClickConfirmarForm={salvarEditarEmpresa}
            validationForm={closeModal}
            datatesteid="buttonCancelModal"
            datatesteidconfirm="buttonConfirmModal"
          />
          <Header title="Empresa" onClick={editEmpresa ? "edit" : "add"} />
          <div className="empresa__wrapper">
            <div className="empresaForm">
              <form>
                <div className="empresaForm--row">
                  <div className="empresa__field">
                    <label>
                      Status <abbr className="form__asterisk">*</abbr>
                    </label>
                    <SelectButton
                      {...register("status")}
                      className="filter-select"
                      options={optionsStatus}
                      value={status}
                      onChange={(e) => {
                        setStatus(e.value);
                        setValue("status", e.value);
                        setAlteracoes(true)
                      }}
                      optionLabel="name"
                      optionValue="value"
                      data-testid="statusTest"
                    />
                    <small className="form__errorMsg">
                      {errors.status?.message}
                    </small>
                  </div>
                  <div className="empresa__field">
                    <label htmlFor="razaoSocial">
                      Grupo Econômico<abbr className="form__asterisk">*</abbr>
                    </label>
                    <AutoCompletarComponent
                      value={nomeFantasiaGrupoEconomico}
                      setValue={setNomeFantasiaGrupoEconomico}
                      options={allGrupoEconomico}
                      filter={setFiltroGrupoEconomicoDrop}
                      genericFunction={() => setAlteracoes(true)}
                      attribute="nomeFantasia"
                      optionLabel="nomeFantasia"
                      errorMessage={errors.nomeFantasiaGrupoEconomico?.message}
                      completeMethod={getGrupoEconomicoList}
                      datatesteid={"grupoEconomicoID"}
                    />
                  </div>
                </div>
                <div className="empresa__field">
                  <label>
                    Razão Social <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputTextarea
                    {...register("razaoSocial")}
                    field="razaoSocial"
                    value={razaoSocial}
                    onChange={(e) => {
                      setRazaoSocial(e.target.value)
                      setAlteracoes(true)
                    }}
                    maxlength="400"
                    rows={2}
                    cols={30}
                  />
                  <small className="form__errorMsg">
                    {errors.razaoSocial?.message}
                  </small>
                </div>
                <div className="empresa__field">
                  <label>
                    Nome Fantasia <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputTextarea
                    {...register("nomeFantasia")}
                    field="nomeFantasia"
                    value={nomeFantasia}
                    onChange={(e) => {
                      setNomeFantasia(e.target.value)
                      setAlteracoes(true)
                    }}
                    maxlength="200"
                    rows={2}
                  />
                  <small className="form__errorMsg">
                    {errors.nomeFantasia?.message}
                  </small>
                </div>

                <div className="empresaForm--row">
                  <div className="empresaForm__section">
                    <div className="empresa__field">
                      <label>
                        CNPJ <abbr className="form__asterisk">*</abbr>
                      </label>
                      <InputMask
                        id="cnpj"
                        mask="99.999.999/9999-99"
                        value={cnpj}
                        onChange={(e) => {
                          setCnpj(e.value);
                          setValue("cnpj", e.value);
                          setAlteracoes(true)
                        }}
                        data-testid="cnpjTest"
                      />
                      <small className="form__errorMsg">
                        {errors.cnpj?.message}
                      </small>
                    </div>
                    <div className="empresa__field">
                      <label>UF da Inscrição Municipal</label>
                      <InputText
                        field="ufInscricao"
                        value={ufInscricao}
                        onChange={(e) => {
                          setUfInscricao(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="2"
                        data-testid="ufInscricaoTest"
                      />
                    </div>
                    <div className="empresa__field">
                      <label>CEP</label>
                      <InputText
                        id="cep"
                        field="cep"
                        value={cep}
                        onChange={(e) => {
                          setCep(e.target.value)
                          setAlteracoes(true)
                        }}
                        onBlur={viaCep}
                        maxLength={8}
                        data-testid="cepTest"
                        keyfilter={onlyNumbersRegex}
                      />
                    </div>
                    <div className="empresa__field">
                      <label>Número do Logradouro</label>
                      <InputText
                        keyfilter="pint"
                        value={numLogradouro}
                        onChange={(e) => {
                          setNumLogradouro(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="10"
                        data-testid="numLogradouroTest"
                      />
                    </div>
                    <div className="empresa__field">
                      <label>Cidade</label>
                      <InputText
                        field="grau"
                        value={cidade}
                        onChange={(e) => {
                          setCidade(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="200"
                        data-testid="cidadeTest"
                      />
                    </div>
                    <div className="empresa__field">
                      <label>Bairro</label>
                      <InputText
                        field="bairro"
                        value={bairro}
                        onChange={(e) => {
                          setBairro(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="100"
                        data-testid="bairroTest"
                      />
                    </div>
                  </div>
                  <div className="empresaForm__section">
                    <div className="empresaForm--row">
                      <div className="empresa__field">
                        <label>Inscrição Estadual</label>
                        <InputText
                          field="inscEstadual"
                          value={inscEstadual}
                          onChange={(e) => {
                            setInscEstadual(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="20"
                          data-testid="inscEstadualTest"
                        />
                      </div>
                      <div className="empresa__field">
                        <label>Inscrição Municipal</label>
                        <InputText
                          field="inscMunicipal"
                          value={inscMunicipal}
                          onChange={(e) => {
                            setInscMunicipal(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="20"
                          data-testid="inscMunicipalTest"
                        />
                      </div>
                    </div>
                    <div className="empresa__field">
                      <label>
                        E-mail para envio da Nota Fiscal
                        <abbr className="form__asterisk">*</abbr>
                      </label>
                      <InputText
                        {...register("emailNotaFiscal")}
                        name="emailNotaFiscal"
                        field="emailNotaFiscal"
                        value={emailNotaFiscal}
                        onChange={(e) => {
                          setEmailNotaFiscal(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="200"
                        data-testid="emailNotaFiscalTest"
                      />
                      <small className="form__errorMsg">
                        {errors.emailNotaFiscal?.message}
                      </small>
                    </div>
                    <div className="empresa__field">
                      <label>Logradouro</label>
                      <InputTextarea
                        name="logradouro"
                        field="logradouro"
                        value={logradouro}
                        onChange={(e) => {
                          setLogradouro(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="300"
                        rows={1}
                        cols={30}
                        data-testid="logradouroTest"
                      />
                    </div>
                    <div className="empresa__field">
                      <label>Complemento</label>
                      <InputText
                        value={complemento}
                        onChange={(e) => {
                          setComplemento(e.target.value)
                          setAlteracoes(true)
                        }}
                        maxlength="100"
                        data-testid="complementoTest"
                      />
                    </div>
                    <div className="empresaForm--row">
                      <div className="empresa__field">
                        <label>Estado</label>
                        <InputText
                          field="estado"
                          value={estado}
                          onChange={(e) => {
                            setEstado(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="20"
                          data-testid="estadoTest"
                        />
                      </div>
                      <div className="empresa__field">
                        <label>País</label>
                        <InputText
                          field="pais"
                          value={pais}
                          onChange={(e) => {
                            setPais(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="20"
                          data-testid="paisTest"
                        />
                      </div>
                    </div>
                    <div className="empresaForm--row">
                      <div className="empresa__field">
                        <label>DDD</label>
                        <InputText
                          field="ddd"
                          value={ddd}
                          onChange={(e) => {
                            setDdd(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="2"
                          data-testid="dddTest"
                        />
                      </div>
                      <div className="empresa__field">
                        <label>Telefone</label>
                        <InputText
                          keyfilter="pint"
                          value={telefone}
                          onChange={(e) => {
                            setTelefone(e.target.value)
                            setAlteracoes(true)
                          }}
                          maxlength="15"
                          data-testid="telefoneTest"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="empresaForm__containerButtons">
                <ButtonComponent
                  type="cancel"
                  onClick={() => validateGoBack()}
                />

                <ButtonComponent
                  datatesteid="buttonConfirmScreen"
                  type="confirm"
                  onClick={() => setDialogCadastroEmpresa(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
