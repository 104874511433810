import { yupResolver } from "@hookform/resolvers/yup";
import _ from 'lodash';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  addProdutoServico,
  esconderLoading,
  exibirLoading,
  setDescricaoProdFiltroDrop,
  setFiltroProdServico,
} from "../../actions";
import {
  deleteProdutoServico,
  getByDescricaoProdutoEAtivo,
  getEmpresaById,
  getEmpresaByNomeFantasiaEAtivo,
  getFilterProdutoServico,
  putProdutoPai,
  putProdutoServico,
  saveProdutoServico,
} from "../../api";
import { FiltroProdutoServico } from "../../components/FiltroProdutoServico";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalAdicao } from "../../components/ModalAdicao";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { messageRequired } from "../../default/messages";
import { paginationDefault } from "../../default/pagination";
import { formatISO8601ToUser } from "../../utils";
import { Navbar } from "../../components/Navbar";

export const ProdutoServico = (props) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const idFiltro = useSelector((state) => state.filtroProd.id);
  const nomeFiltro = useSelector((state) => state.filtroProd.nome);
  const descricaoFiltro = useSelector((state) => state.filtroProd.descricao);

  const valueDropDescricaoProduto = useSelector(
    (state) => state.descricaoProdFiltroDrop.descricao
  );
  const descricaoFiltroPai = useSelector(
    (state) => state.filtroProd.produtoPai.descricao
  );
  const statusFiltro = useSelector((state) => state.filtroProd.status);
  const [filtroProdServ, setFiltroProdServ] = useState(false);
  const listaProdutoServico = useSelector(
    (state) => state.grupoEconomico.produtoServico
  );
  const [abrirLinhaEditar, setAbrirLinhaEditar] = useState(false);
  const [nome, setNome] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [status, setStatus] = useState(null);
  const [id, setId] = useState(null);
  const [excluirProdServico, setExcluirProdServico] = useState(""); //excluir linha
  const [descricaoModalExcluir, setDescricaoModalExcluir] = useState("");
  const [descricaoPai, setDescricaoPai] = useState(null);
  const [listPai, setListPai] = useState([]);
  const [dialogExcluirProdServico, setDialogExcluirProdServico] =
    useState(false);
  const [nomeNull, setNomeNull] = useState(true);
  const [descricaoNull, setDescricaoNull] = useState(true);
  const [statusNull, setStatusNull] = useState(true);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );

  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);

  const [abrirModalSalvarProdServ, setAbrirModalSalvarProdServ] = useState(false);

  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(paginationDefault);
  const reduxStateAtual = useSelector(state => state.filtroProd);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState('');
  const [clearInputs, setClearInputs] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [listaEmpresa, setListaEmpresa] = useState([]);

  function searchNotFound(detail) {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: detail,
    });
  }

  function searchHandlerError(name) {
    toast.current.show({
      severity: "error",
      summary: `Erro ao buscar por ${name}`,
      detail: `Houve um problema inesperado e a busca falhou, tente novamente mais tarde`,
    });
  }

  function getListWith({
    endpoint,
    searchBy,
    setState,
    notFoundMessage,
    errorMessageFrom,
  }) {
    endpoint(searchBy)
      .then((response) => {
        setState(response);
        if (response.length === 0) {
          searchNotFound(notFoundMessage);
        }
      })
      .catch(() => searchHandlerError(errorMessageFrom));
  }

  function getListaEmpresa(nomeFantasia) {
    getListWith({
      endpoint: getEmpresaByNomeFantasiaEAtivo,
      searchBy: nomeFantasia,
      setState: setListaEmpresa,
      notFoundMessage: "Empresa não encontrada",
      errorMessageFrom: "empresa",
    });
  }

  async function clearFilter() {
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial.filtroProd)
    setVisible(!filterActive)
    if (filterActive === false) {
      setClear(true)
      dispatch(setFiltroProdServico(reduxStateInicial.filtroProd));
      // setVisible(false)
    } else {
      setClear(false)
    }
  }

  const optionsStatus = [
    { name: "Ativo", value: "ATIVA" },
    { name: "Inativo", value: "INATIVA" },
  ];

  function functionExcluirProdutoServico(rowData) {
    setDialogExcluirProdServico(true);
    setExcluirProdServico(rowData.id);
    setDescricaoModalExcluir(rowData.descricao);
  }

  function verificaPai() {
    if (!id && descricaoPai?.id) {
      const produtoTratado = {
        descricao: descricao,
        nome: nome,
        status: status,
        produtoPai: { id: descricaoPai?.id },
        idEmpresa: empresa?.id,
      };
      return produtoTratado;
    } else {
      const produtoTratado = {
        descricao: descricao,
        nome: nome,
        status: status,
        idEmpresa: empresa?.id,
      };
      return produtoTratado;
    }
  }

  async function editarProdutoServico(listaProdutoServico) {
    const idEmpresa = listaProdutoServico?.idEmpresa
    try {
      setCarregandoRequisicao(true)
      if (idEmpresa) {
        await getEmpresaById(idEmpresa).then(e => setEmpresa(e))
      }
    } catch (error) {

    } finally {
      setCarregandoRequisicao(false)
    }
    setAbrirLinhaEditar(true);
    setId(listaProdutoServico.id);
    setNome(listaProdutoServico.nome);
    setDescricao(listaProdutoServico.descricao);
    setStatus(listaProdutoServico.status);
    setDescricaoPai(listaProdutoServico?.produtoPai?.descricao);
    setValue("status", listaProdutoServico.status);
    setValue("nome", listaProdutoServico.nome);
    setValue("descricao", listaProdutoServico.descricao);
  }

  const validationPost = yup
    .object({
      status: yup.string().required(messageRequired),
      nome: yup.string().required(messageRequired).trim(),
      descricao: yup.string().required(messageRequired).trim(),
      empresa: yup.string().required(messageRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const closeModal = () => {
    validationPost.isValid(verificaPai()).then((response) => {
      setAbrirModalSalvarProdServ(false);
    });
  };

  function cancelarProdServ() {
    setAlteracoes(false)
    setAlteracoesFeitas(false);
    setAbrirLinhaEditar(false);
    setNome(null);
    setEmpresa(null);
    setDescricao(null);
    setStatus(null);
    setNomeNull(true);
    setDescricaoNull(true);
    setStatusNull(true);
    setId(null);
  }

  async function atualizaPai() {
    if (descricaoPai?.id) {
      try {
        await putProdutoPai(id, descricaoPai?.id);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function salvarEditarProdutoServico() {
    setCarregandoRequisicao(true);
    try {
      const reduxEqual = _.isEqual(reduxStateAtual, reduxStateInicial.filtroProd)
      if (id === null) {
        await saveProdutoServico(verificaPai()).then(() => {
          if (reduxEqual === true) {
            carregarListaProdutoServico();
          } else {
            dispatch(setFiltroProdServico(reduxStateInicial.filtroProd))
            setClearInputs(true)
          }
        });
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Produto ou Serviço criado com sucesso!",
        });
      } else {
        await putProdutoServico(verificaPai(), id).then(async () => {
          await atualizaPai();
          if (reduxEqual === true) {
            carregarListaProdutoServico();
          } else {
            dispatch(setFiltroProdServico(reduxStateInicial.filtroProd))
            setClearInputs(true)
          }
        });
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Produto ou Serviço alterado com sucesso!",
        });
      }
      setTimeout(() => {
        setAbrirLinhaEditar(false);
        setAbrirModalSalvarProdServ(false);
      }, 500);
      setDescricao(null)
      setNome(null)
      setStatus(null)
      setDescricaoPai(null)
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro",
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const montarFiltro = useCallback(() => {
    const filtroProdutoServico = {
      descricao: descricaoFiltro,
      nome: nomeFiltro,
      id: idFiltro,
      produtoPai: {
        descricao: descricaoFiltroPai,
      },
      status: statusFiltro,
    };
    return filtroProdutoServico;
  }, [
    descricaoFiltro,
    nomeFiltro,
    idFiltro,
    statusFiltro,
    descricaoFiltroPai,
  ]);

  async function deleteProdServico(rowData) {
    setCarregandoRequisicao(true);
    try {
      await deleteProdutoServico(rowData).then(() => {
        dispatch(setFiltroProdServico(reduxStateInicial.filtroProd))
        setClearInputs(true)
      });
      setDialogExcluirProdServico(false);
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Produto ou Serviço excluído com sucesso!',
        life: 5000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 5000,
        detail: error?.response?.data?.userMessage,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }
  const carregarListaProdutoServico = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };

    getFilterProdutoServico(requestParams).then((resultado) => {
      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
      }
      dispatch(addProdutoServico(resultado.content));
      setTotalRecords(resultado.totalElements);
      dispatch(esconderLoading());
    });
  },[dispatch, lazyParams, montarFiltro]);

  useEffect(() => {
    const buttonClearFilter = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroProd)
    setVisible(!buttonClearFilter)
    carregarListaProdutoServico();
  }, [montarFiltro, lazyParams, carregarListaProdutoServico, reduxStateAtual, reduxStateInicial]);

  function verificarStatus(rowData) {
    //mostra icone se estiver ativa
    if (rowData.status === "ATIVA") {
      return (
        <Button
          className="opcoes-lupa p-button-secondary"
          style={{ color: "green", background: 'transparent', border: 'none' }}
          icon="pi pi-check-circle"
          tooltip="Ativo"
          tooltipOptions={{ position: "left" }}
        />
      );
    } else if (rowData.status === "INATIVA") {
      return (
        <Button
          className="opcoes-lupa p-button-secondary"
          style={{ color: "red", background: 'transparent', border: 'none' }}
          icon="pi pi-times-circle"
          tooltip="Inativo"
          tooltipOptions={{ position: "left" }}
        />
      );
    }
  }

  const columns = [
    { 
      field: "id",
      header: "Código",
      style: { maxWidth: "6vw" },
      type: "customEllipsis",
    },
    {
      field: "produtoPai.id",
      header: "Código Pai",
      style: { maxWidth: "6vw" },
      type: "customEllipsis",
    },
    {
      field: "produtoPai.descricao",
      header: "Descrição Pai",
      style: { maxWidth: "19vw" },
      type: "customEllipsis",
    },
    {
      field: "nome",
      header: "Nome",
      style: { maxWidth: "19vw" },
      type: "customEllipsis",
    },
    {
      field: "descricao",
      header: "Descrição",
      style: { maxWidth: "20vw" },
      type: "customEllipsis",
    },
    {
      field: "status",
      header: "Status",
      style: { maxWidth: "6vw" },
      type: "custom",
      customFunc: verificarStatus,
    },
    {
      field: "dataCadastro",
      header: "Data Cadastro",
      style: { maxWidth: "10vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataCadastro),
    },
    {
      field: "dataAlteracao",
      header: "Data Alteração",
      style: { maxWidth: "10vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataAlteracao),
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: excluirProdServico },
    { label: "Descrição:", value: descricaoModalExcluir },
  ];

  useEffect(() => {
    setValue("status", status);
    setValue("nome", nome);
    setValue("descricao", descricao);
    setValue("empresa", empresa?.id);
  }, [status, nome, descricao, empresa, setValue]);

  useEffect(() => {
    callFilterEndpoints(
      setListPai,
      getByDescricaoProdutoEAtivo,
      valueDropDescricaoProduto,
      "Pai"
    );
  }, [valueDropDescricaoProduto]);

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null && param !== undefined) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não encontrado. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const addEditfields = [
    {
      label: "Status",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      setAlteracoes: setAlteracoes,
      optionValue: "value",
      isEmpty: !statusNull,
      required: true,
      validacao: { ...register("status") },
      errorMessage: errors.status?.message,
    },
    {
      label: "Nome",
      value: nome,
      setState: setNome,
      type: "textArea",
      setAlteracoes: setAlteracoes,
      rows: 5,
      isEmpty: !nomeNull,
      maxLength: 500,
      required: true,
      validacao: { ...register("nome") },
      errorMessage: errors.nome?.message,
      datatesteid: "nomeID",
    },
    {
      label: "Descrição",
      value: descricao,
      setState: setDescricao,
      setAlteracoes: setAlteracoes,
      type: "textArea",
      maxLength: 200,
      rows: 5,
      isEmpty: !descricaoNull,
      required: true,
      validacao: { ...register("descricao") },
      errorMessage: errors.descricao?.message,
      datatesteid: "descricaoID",
    },
    {
      label: "Pai",
      value: descricaoPai,
      setValue: setDescricaoPai,
      type: "autoComplete",
      genericFunction: () => setAlteracoes(true),
      datatesteid: "autocompletePai",
      filter: setDescricaoProdFiltroDrop,
      options: listPai.filter((item) => item.id !== id),
      optionLabel: "descricao",
      attribute: "descricao",
    },
    {
      label: "Empresa",
      value: empresa,
      setValue: setEmpresa,
      type: "autoCompletar",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      required: true,
      validacao: { ...register("empresa") },
      errorMessage: errors.empresa?.message,
      genericFunction: () => setAlteracoes(true),
      completeMethod: getListaEmpresa,
    },
  ];

  function handleAbrirModalSalvarProdServ() {
    setDescricaoPai(null)
    setAbrirLinhaEditar(true)
  }

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true)
    } else {
      cancelarProdServ()
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <ModalComponents
          title="Produto e Serviço"
          visible={dialogExcluirProdServico}
          onHide={() => setDialogExcluirProdServico(false)}
          onClick="delete"
          onClickCancelar={() => setDialogExcluirProdServico(false)}
          onClickConfirmar={() => deleteProdServico(excluirProdServico)}
          descricao={modalExcluir}
          datatesteidconfirm="confirmDeleteID"
        />
        <ModalAdicao
          title={
            id ? "Editar Produto e Serviço" : "Adicionar Produto e Serviço"
          }
          onHide={() => validateGoBack()}
          visible={abrirLinhaEditar}
          setVisible={setAbrirLinhaEditar}
          cancel={() => validateGoBack()}
          confirm={() => setAbrirModalSalvarProdServ(true)}
          fields={addEditfields}
          onSubmit={salvarEditarProdutoServico}
        />

        <ModalComponents
          title={
            id ? "Deseja confirmar a alteração de Produto e Serviço?" : "Deseja confirmar o cadastro de Produto e Serviço"
          }
          visible={abrirModalSalvarProdServ}
          onHide={() => setAbrirModalSalvarProdServ(false)}
          onClick="custom"
          onClickCancelar={() => setAbrirModalSalvarProdServ(false)}
          handleSubmit={handleSubmit}
          onClickConfirmarForm={() => salvarEditarProdutoServico()}
          validationForm={closeModal}
          datatesteidconfirm="confirmarTestID"
          datatesteid="cancelTestID"
        />

        <Table
          arrayData={listaProdutoServico}
          loading={recarregando}
          headerTitle="Produtos e Serviços"
          columns={columns}
          onClickFiltrar={() => setFiltroProdServ(true)}
          onClickAdicionar={() => handleAbrirModalSalvarProdServ()}
          onClickEdit={(rowData) => editarProdutoServico(rowData)}
          tooltipEdit="Produto e Serviço"
          onClickDelete={(rowData) => functionExcluirProdutoServico(rowData)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <FiltroProdutoServico
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setFiltroProdServ={setFiltroProdServ}
          filtroProdServ={filtroProdServ}
          setLazyParamsProp={setLazyParams}
        />

        <ModalComponents
          visible={alteracoesFeitas}
          onHide={() => setAlteracoesFeitas(false)}
          onClickCancelar={() => setAlteracoesFeitas(false)}
          onClickConfirmar={() => {
            cancelarProdServ()

          }}
          onClick="warning"
          descricao={modalWarning}
        />
      </LoadingOverlayComponents>
    </>
  );
};
