import _ from 'lodash';
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addListaConciliacao,
  esconderLoading,
  exibirLoading,
  setFiltroConci,
  setTamanhoTotal,
} from "../../actions";
import {
  getListaConciliacao,
  postSalvarConciliacao,
  postSalvarDesconciliacao,
} from "../../api";
import { DetalhesListaPorLinha } from "../../components/DetalhesListaPorLinha";
import { FiltroConciliacao } from "../../components/FiltroConciliacao";
import { HeaderComCalculos } from "../../components/HeaderComCalculos";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ModalDescricaoDeCores } from "../../components/ModalDescricaoDeCores";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { formatTypeConciliacao } from '../../default/types';
import { formatISO8601ToUser, transformDateToEndOfDay, transformDateToStartOfDay } from "../../utils";
import { formatValue } from "../../utils/value";
import "./styles.css";
import { Navbar } from '../../components/Navbar';

export const ConciliacaoBancaria = (props) => {
  const pageRedux = useSelector(
    (state) => state.filterPage
  )
  const page = pageRedux?.page || props?.location?.state?.page
  const dispatch = useDispatch();
  const [visibleRight, setVisibleRight] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const conciliacaoInicio = useSelector(
    (state) => state.filtroConci.conciliacaoInicio
  );
  const conciliacaoFim = useSelector(
    (state) => state.filtroConci.conciliacaoFim
  );
  const ultimaAlteracaoInicio = useSelector(
    (state) => state.filtroConci.ultimaAlteracaoInicio
  );
  const ultimaAlteracaoFim = useSelector(
    (state) => state.filtroConci.ultimaAlteracaoFim
  );
  const desconciliadoInicio = useSelector(
    (state) => state.filtroConci.desconciliadoInicio
  );
  const desconciliadoFim = useSelector(
    (state) => state.filtroConci.desconciliadoFim
  );
  const vencimentoModificadoInicio = useSelector(
    (state) => state.filtroConci.vencimentoModificadoInicio
  );
  
  const [vencimentoModificadoFim, setVencimentoModificadoFim] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10));

  const cadastroInicio = useSelector(
    (state) => state.filtroConci.cadastroInicio
  );
  const cadastroFim = useSelector((state) => state.filtroConci.cadastroFim);
  const dataBaixaInicio = useSelector(
    (state) => state.filtroConci.dataBaixaInicio
  );
  const dataBaixaFim = useSelector((state) => state.filtroConci.dataBaixaFim);
  const idMovimentacao = useSelector(
    (state) => state.filtroConci.idMovimentacao
  );
  const tipoBaixa = useSelector((state) => state.filtroConci.tipoBaixa);
  const valorLiquido = useSelector((state) => state.filtroConci.valorLiquido);
  const historico = useSelector((state) => state.filtroConci.historico);
  const tipoLancamento = useSelector(
    (state) => state.filtroConci.tipoLancamento
  );
  const contaCorrente = useSelector((state) => state.filtroConci.contaCorrente);
  const tipoConciliacao = useSelector(
    (state) => state.filtroConci.tipoConciliacao
  );

  const toast = useRef(null);
  const [id, setId] = useState([]);

  const conciliacoes = useSelector(
    (state) => state.conciliacao.listaConciliacao
  );
  const [displayBasic, setDisplayBasic] = useState(false); //abre modal que concilia
  const [displayDesconci, setDisplayDesconci] = useState(false); //abre modal para desconciliar
  const [listaCheck, setListaCheck] = useState([]);
  const history = useHistory();
  const [totalDebito, setTotalDebito] = useState(null);
  const [totalCredito, setTotalCredito] = useState(null);
  const [totalLiquido, setTotalLiquido] = useState(null);
  const [totalConciliado, setTotalConciliado] = useState(null);
  const loading = useSelector((state) => state.conciliacao.loading);
  const [exibirDetalhesLinha, setExibirDetalhesLinha] = useState(false);
  const [conteudoPorLinha, setConteudoPorLinha] = useState(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const idUsuarioConciliado = 12; //TROCAR O ID DO USUARIO CONCILIADO
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);
  const reduxStateAtual = useSelector(state => state.filtroConci);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState('');
  const [clearInputs, setClearInputs] = useState(false);


  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])

  async function clearFilter() {
    const reduxObj = reduxStateAtual
    const reduxObj2 = reduxStateInicial.filtroConci
    const obj1 = JSON.stringify(reduxObj)
    const obj2 = JSON.stringify(reduxObj2)
    const filterActive = obj1 === obj2
    setVisible(!filterActive)
    setClear(true)
    const filtroConciliacao = { ...reduxStateInicial.filtroConci };
    filtroConciliacao.tipoLancamento = "TODOS"
    filtroConciliacao.tipoConciliacao = "TODOS"
    setVencimentoModificadoFim(null)
    dispatch(setFiltroConci(filtroConciliacao));
  }

  useEffect(() => {
    //Ajusta loading da tela ao sair da tela de cadastro.
    document.body.style.zoom = 1.0;
  }, []);

  const selecionarDetalhesPorLinha = (rowData) => {
    setExibirDetalhesLinha(true);
    setConteudoPorLinha(rowData);
  };

  function editarConciliacao(rowData) {
    rowData.retorno = "/conciliacoes-bancarias";
    const idMovimentacao = rowData.id
    console.log('rowDatarowData', rowData)
    history.push(`/movimentacao/${idMovimentacao}`, { page: lazyParams });
  }

  const rowClassName = (rowData) => {
    if (rowData?.baixa === null && rowData?.valorBaixa === null) {
      return "faturar-em-dia";
    } else {
      return '';
    }
  };

  async function PostSalvarConciliacao() {
    const listaParaConciliar = {
      listaIdsConciliacao: listaCheck,
    };
    if (listaParaConciliar.listaIdsConciliacao.length === 0) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Selecione uma conciliação",
        life: 5000,
      });
    }
    else {
      try {
        setCarregandoRequisicao(true);
        await postSalvarConciliacao(idUsuarioConciliado, listaParaConciliar);
        toast.current.show({
          severity: "success",
          summary: "Info",
          detail: "Os registros foram conciliados com sucesso",
          life: 5000,
        });
        window.location.reload()
        // carregarConciliacao();
        // setListaCheck([])
      }
      catch (e) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Uma ou mais movimentações selecionadas não possuem baixas",
          life: 5000,
        });
      } finally {
        setDisplayBasic(false);
        setCarregandoRequisicao(false);
      }
    }
  }

  async function PostSalvarDesconciliacao() {
    const body = {
      listaIdsConciliacao: listaCheck,
    };
    if (body.listaIdsConciliacao.length === 0) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Selecione uma conciliação",
        life: 5000,
      });
    } else {
      setCarregandoRequisicao(true);
      try {
        await postSalvarDesconciliacao(idUsuarioConciliado, body);
        toast.current.show({
          severity: "success",
          summary: "Info",
          detail: "Os registros foram desconciliados com sucesso",
          life: 5000,
        });
        window.location.reload()
        // carregarConciliacao();
        // setListaCheck([])
      }
      catch (e) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Não foi possível Desconciliar",
          life: 5000,
        });
      } finally {
        setDisplayDesconci(false);
        setCarregandoRequisicao(false);
      }
    }
  }

  function handleCheck(e) {
    //marcar cada linha
    const listaId = e.map((c) => c.id);
    setListaCheck(listaId);
  }

  const submitAll = () => {
    //confirma conciliação
    PostSalvarConciliacao();
  };

  const submitDesconciliado = () => {
    // Popup que Desconcilia
    PostSalvarDesconciliacao();
  };

  const dialogFuncMap = {
    displayBasic: listaCheck.length === 0 ? () => toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "Selecione uma conciliação",
      life: 5000,
    }) : setDisplayBasic,
    displayDesconci: listaCheck.length === 0 ? () => toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "Selecione uma conciliação",
      life: 5000,
    }) : setDisplayDesconci,
  };

  const onOpen = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  function formatTypeBaixa(type) {
    if (type?.tipoBaixa === 'T') {
      return "Automática "
    } else if (type?.tipoBaixa === 'M') {
      return "Manual"
    } else if (type?.tipoBaixa === 'A') {
      return "Arquivo"
    }
    return '';
  }

  const atualizaConciliacao = useCallback(
    (listaConciliacao) => {
      dispatch(addListaConciliacao(listaConciliacao));
    },
    [dispatch]
  );

  const montarFiltroConci = useCallback(() => {
    let filtroConci = {
      conciliacaoInicio,
      conciliacaoFim,
      ultimaAlteracaoInicio: ultimaAlteracaoInicio && transformDateToStartOfDay(ultimaAlteracaoInicio),
      ultimaAlteracaoFim: ultimaAlteracaoFim && transformDateToEndOfDay(ultimaAlteracaoFim),
      desconciliadoInicio,
      desconciliadoFim,
      cadastroInicio: cadastroInicio && transformDateToStartOfDay(cadastroInicio),
      cadastroFim: cadastroFim && transformDateToEndOfDay(cadastroFim),
      dataBaixaInicio: dataBaixaInicio && transformDateToStartOfDay(dataBaixaInicio),
      dataBaixaFim: dataBaixaFim && transformDateToEndOfDay(dataBaixaFim),
      idMovimentacao,
      tipoBaixa,
      valorLiquido,
      historico,
      tipoLancamento: tipoLancamento === null ? "TODOS" : tipoLancamento,
      contaCorrente,
      tipoConciliacao: tipoConciliacao === null ? 'PENDENTE' : tipoConciliacao === "TODOS" ? null : tipoConciliacao,
      vencimentoModificadoInicio,
      vencimentoModificadoFim
    };

    return filtroConci;
  }, [
    conciliacaoInicio,
    conciliacaoFim,
    ultimaAlteracaoInicio,
    ultimaAlteracaoFim,
    desconciliadoInicio,
    desconciliadoFim,
    cadastroInicio,
    cadastroFim,
    dataBaixaInicio,
    dataBaixaFim,
    idMovimentacao,
    tipoBaixa,
    valorLiquido,
    historico,
    tipoLancamento,
    contaCorrente,
    tipoConciliacao,
    vencimentoModificadoInicio,
    vencimentoModificadoFim
  ]);

  const carregarConciliacao = useCallback(() => {
    dispatch(exibirLoading());
    const filtroConci = montarFiltroConci();
    const requestParams = {
      params: filtroConci,
      paginationParams: lazyParams,
    };

    getListaConciliacao(requestParams)
      .then((resultado) => {
        if (!resultado || resultado.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "Não obteve resultado",
          });
        }
        console.log('resultado.content[0]', resultado.content[0])
        setTotalCredito(resultado.content[0].totalCredito)
        setTotalDebito(resultado.content[0].totalDebito)
        setTotalLiquido(resultado.content[0].vlrTotalLiquido)
        setTotalConciliado(resultado.content[0].totalConciliado)
        atualizaConciliacao(resultado.content);
        dispatch(setTamanhoTotal(resultado.length));
        dispatch(esconderLoading());
        setTotalRecords(resultado.totalElements);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: error?.response?.data?.userMessage,
        });
        atualizaConciliacao([]);
      });
    setVisibleRight(false);
  }, [atualizaConciliacao, dispatch, lazyParams, montarFiltroConci])

  useEffect(() => {
    const reduxObj = montarFiltroConci()
    const reduxObj2 = reduxStateInicial?.filtroConci

    const copy1 = { ...reduxObj }
    const copy2 = { ...reduxObj2 }
    if (copy1.vencimentoModificadoFim === new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10)) {
      copy1.vencimentoModificadoFim = null
    }
    if (copy1.tipoLancamento === "TODOS") {
      copy1.tipoLancamento = null
    }
    if (copy1.tipoConciliacao === "TODOS") {
      copy1.tipoConciliacao = null
    }
    const areEqual = _.isEqual(copy1, copy2)

    console.log('aksdhksjadhsa', copy1)
    console.log('aksdhksjadhsa', copy2)
    console.log('aksdhksjadhsa', areEqual)

    setVisible(!areEqual)
    carregarConciliacao();
  }, [lazyParams, montarFiltroConci, carregarConciliacao, reduxStateInicial]);

  const columns = [
    {
      field: "",
      header: "",
      style: { minWidth: 50, maxWidth: "5vw" },
      type: "checkbox",
    },
    {
      field: "tipoLancamento",
      header: "",
      style: { minWidth: 50, maxWidth: "3vw", fontSize: 24 },
      type: "custom",
      customFunc: (e) => formatTypeConciliacao(e?.tipoLancamento),
    },
    {
      field: "baixa",
      header: "Baixa",
      style: { minWidth: 70, maxWidth: "6vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.baixa),
    },
    {
      field: "tipoBaixa",
      header: "Tipo Baixa",
      style: { minWidth: 80, maxWidth: "5vw" },
      type: "custom",
      customFunc: formatTypeBaixa,
    },
    {
      field: "idUsuarioConciliado",
      header: "Usu Conc.",
      style: { minWidth: 60, maxWidth: "5vw" },
    },
    {
      field: "idUsuarioDesconciliado",
      header: "Usu Desc.",
      style: { minWidth: 60, maxWidth: "5vw" },
    },
    {
      field: "id",
      header: "Código",
      style: { minWidth: 50, maxWidth: "5vw" },
    },
    {
      field: "dataConciliado",
      header: "Data Conciliado",
      style: { minWidth: 100, maxWidth: "8vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataConciliado),
    },
    {
      field: "dataDesconciliado",
      header: "Data Desconciliado",
      style: { minWidth: 110, maxWidth: "8vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataDesconciliado),
    },
    {
      field: "ultimaAlteracao",
      header: "Ultima Alteração",
      style: { minWidth: 100, maxWidth: "8vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.ultimaAlteracao),
    },
    {
      field: "formaPagamento",
      header: "Tipo Pagamento",
      style: { minWidth: 100, maxWidth: "12vw" },
      type: "customEllipsis"
    },
    {
      field: "valorLiquido",
      header: "Valor Líquido",
      style: { minWidth: 100, maxWidth: "10vw" },
      type: "customRight",
      customFunc: (e) => formatValue(e.valorLiquido ? e.valorLiquido : 0),
    },
    {
      field: "historico",
      header: "Histórico",
      type: "customEllipsis",
      style: { minWidth: 80, maxWidth: "10vw" },
    },
  ];

  const descricaoStatusParcela = [
    {
      nome: "CONCILIAÇÃO",
      descricao: "Movimentação com baixa pendente",
      cor: "#CCC",
    },
  ];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <DetalhesListaPorLinha
          setExibirDetalhesLinha={setExibirDetalhesLinha}
          exibirDetalhesLinha={exibirDetalhesLinha}
          conteudoPorLinha={conteudoPorLinha}
        />
        <Table
          rowClassName={(rowData) => rowClassName(rowData)}
          arrayData={conciliacoes}
          loading={loading}
          selectionMode="checkbox"
          selection={id}
          metaKeySelection={false}
          onSelectionChange={(e) => {
            setId(e.value);
            handleCheck(e.value);
          }}
          setId={setId}
          handleCheck={handleCheck}
          dataKey="id"
          columns={columns}
          headerTitle={"Conciliação Bancária"}
          onClickDetails={selecionarDetalhesPorLinha}
          onClickEdit={(rowData) => editarConciliacao(rowData)}
          tooltipEdit="Conciliação"
          headerComponent={
            <HeaderComCalculos
              title={"Conciliação Bancária"}
              totalCredito={totalCredito}
              totalDebito={totalDebito}
              totalLiquido={totalLiquido}
              totalConciliado={totalConciliado}
              setVisibleRight={setVisibleRight}
              onOpen={onOpen}
              onClickHeaderInfo={() => setShowModalInfo(true)}
              headerInfoHoverMessage="Mostrar descrição de cores"
              onClickClear={() => clearFilter()}
              visible={visible}
              totalRecords={totalRecords}
            />
          }
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
        />
        <ModalComponents
          conciliacao={true}
          onClick="conciliation"
          onClickConfirmar={(e) => {
            submitAll();
          }}
          onClickCancelar={() => onHide("displayBasic")}
          visible={displayBasic}
          onHide={() => onHide("displayBasic")}
          datatesteidconfirm="confirmID"
          datatesteid="cancelID"
        />
        <ModalComponents
          conciliacao={false}
          onClick="conciliation"
          onClickConfirmar={(e) => {
            submitDesconciliado();
          }}
          onClickCancelar={() => onHide("displayDesconci")}
          visible={displayDesconci}
          onHide={() => onHide("displayDesconci")}
          datatesteidconfirm="confirmID"
          datatesteid="cancelID"
        />
        <ModalDescricaoDeCores
          datatesteId={"modal__info"}
          visible={showModalInfo}
          setState={setShowModalInfo}
          arrayDescricao={descricaoStatusParcela}
          title="Mostrar descrição de cores"
        />
        <FiltroConciliacao
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setVisibleRight={setVisibleRight}
          visibleRight={visibleRight}
          setLazyParamsProp={setLazyParams}
        />
      </LoadingOverlayComponents>
    </>
  );
};
