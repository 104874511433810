import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { saveParametroSistema } from "../../api";
import { messageRequired } from "../../default/messages";
import { ButtonComponent } from "../ButtonComponent";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import "./styles.css";

export const EditarParametroSistema = ({ editarParametroSistema, page }) => {
  let history = useHistory();
  const toast = useRef(null);

  const [id, setId] = useState("");
  const [descricao, setDescricao] = useState(null);
  const [dominio, setDominio] = useState(null);
  const [valorParam, setValorParam] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [dataCadastro, setDataCadastro] = useState(new Date());
  const [dataUltAlteracao, setDataUltAlteracao] = useState(new Date());
  const [sistema, setSistema] = useState(null);
  const [dialogSalvar, setDialogSalvar] = useState(false);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);

  const optionsFormaPagamento = [
    { name: "SISTEMA", value: "SISTEMA" },
    { name: "NEGÓCIO", value: "NEGOCIO" },
  ];

  const tiposDeSistema = [
    { name: "NTISS", value: "NTISS" },
    { name: "FINANCEIRO", value: "FINANCEIRO" },
  ];

  const validationPost = yup
    .object({
      descricao: yup.string().required(messageRequired),
      dominio: yup.string().required(messageRequired),
      valor: yup.string().required(messageRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    if (
      editarParametroSistema !== undefined &&
      editarParametroSistema !== null
    ) {
      setId(editarParametroSistema.id);
      setDescricao(editarParametroSistema.descricao);
      setDominio(editarParametroSistema.dominio);
      setValorParam(editarParametroSistema.valor);
      setTipo(editarParametroSistema.tipo);
      setDataCadastro(
        editarParametroSistema.dataCadastro
          ? new Date(editarParametroSistema.dataCadastro)
          : null
      );
      setDataUltAlteracao(
        editarParametroSistema.dataCadastro
          ? new Date(editarParametroSistema.dataCadastro)
          : null
      );
      setSistema(editarParametroSistema.sistema);
      setValue("descricao", editarParametroSistema.descricao);
      setValue("dominio", editarParametroSistema.dominio);
      setValue("valor", editarParametroSistema.valor);
    }
  }, [editarParametroSistema, setValue]);

  const parametroSistema = {
    id: id,
    dominio: dominio,
    descricao: descricao,
    valor: valorParam,
    tipo: tipo,
    dataCadastro: dataCadastro,
    dataUltAlteracao: dataUltAlteracao,
    sistema: sistema,
  };

  async function salvar() {
    setCarregandoRequisicao(true);
    try {
      await saveParametroSistema(parametroSistema).then((resultado) => {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Parâmetro de Sistema salvo com sucesso!",
        });
        setTimeout(() => {
          history.push("/parametros-do-sistema");
        }, 1500);
      });
    } catch (e) {
      const mensagem = e?.response?.data?.message;
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${mensagem}`,
        life: 8000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  function sistemaParaColocarETirarDrop() {
    if (tipo === "SISTEMA") {
      return (
        <div className="paramSistemaForm__field">
          <label htmlFor="codigoExterno">Sistema</label>
          <Dropdown
            optionLabel="name"
            value={sistema}
            options={tiposDeSistema}
            onChange={(e) => setSistema(e.value)}
            field="name"
            filter
            filterBy="name"
            data-testid="sistemaID"
          />
        </div>
      );
    } else {
      return null;
    }
  }

  const closeModal = () => {
    validationPost.isValid(parametroSistema).then((response) => {
      if (!response) {
        setDialogSalvar(false);
      }
    });
  };

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true);
    } else {
      history.push("/parametros-do-sistema", { page: page });
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <div className="paramSistema__wrapper">
          <Toast ref={toast} />
          <ModalComponents
            onClick="confirm"
            visible={dialogSalvar}
            onHide={() => setDialogSalvar(false)}
            onClickCancelar={() => setDialogSalvar(false)}
            editOrRegister={editarParametroSistema}
            title="Parâmetros do Sistema"
            handleSubmit={handleSubmit}
            onClickConfirmarForm={() => salvar()}
            validationForm={closeModal}
            datatesteidconfirm={"confirmarModal"}
            datatesteid={"cancelarModal"}
          />
          <Header
            title={"Parâmetros do Sistema"}
            onClick={editarParametroSistema ? "edit" : "add"}
          />
          <div className="paramSistema__container">
            <form className="paramSistemaForm">
              <div className="paramSistemaForm__section">
                <div className="paramSistemaForm__field">
                  <label>
                    Descrição <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputTextarea
                    {...register("descricao")}
                    value={descricao}
                    onChange={(e) => {
                      setDescricao(e.target.value);
                      setAlteracoes(true);
                    }}
                    rows={3}
                    maxlength="500"
                  />
                  <small className="form__errorMsg">
                    {errors.descricao?.message}
                  </small>
                </div>
                <div className="paramSistemaForm__field">
                  <label>
                    Domínio <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputTextarea
                    {...register("dominio")}
                    field="dominio"
                    id="dominio"
                    value={dominio}
                    onChange={(e) => {
                      setDominio(e.target.value);
                      setAlteracoes(true);
                    }}
                    maxlength="100"
                    rows={3}
                  />
                  <small className="form__errorMsg">
                    {errors.dominio?.message}
                  </small>
                </div>

                <div className="paramSistemaForm__field">
                  <label>
                    Valor <abbr className="form__asterisk">*</abbr>
                  </label>
                  <InputText
                    {...register("valor")}
                    field="valor"
                    id="valor"
                    value={valorParam}
                    onChange={(e) => {
                      setValorParam(e.target.value);
                      setAlteracoes(true);
                    }}
                    maxlength="100"
                    data-testid="valorInput"
                  />
                  <small className="form__errorMsg">
                    {errors.valor?.message}
                  </small>
                </div>
              </div>
              <div className="paramSistemaForm__section">
                <div className="paramSistemaForm__field">
                  <label>Tipo</label>
                  <Dropdown
                    optionLabel="name"
                    value={tipo}
                    options={optionsFormaPagamento}
                    onChange={(e) => {
                      setTipo(e.value);
                      setAlteracoes(true);
                    }}
                    field="name"
                    filter
                    filterBy="name"
                    data-testid="tipoDrop"
                  />
                </div>
                <div className="paramSistemaForm__field">
                  {sistemaParaColocarETirarDrop()}
                </div>
              </div>
            </form>

            <ModalComponents
              visible={alteracoesFeitas}
              onHide={() => setAlteracoesFeitas(false)}
              onClickCancelar={() => setAlteracoesFeitas(false)}
              onClickConfirmar={() => {
                history.push("/parametros-do-sistema", { page: page });
              }}
              onClick="warning"
              descricao={modalWarning}
            />

            <div className="paramSistemaForm__containerButtons">
              <ButtonComponent type="cancel" onClick={() => validateGoBack()} />

              <ButtonComponent
                type="confirm"
                onClick={() => setDialogSalvar(true)}
              />
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
